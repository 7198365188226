.contentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.hero {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.hero .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: .5;
}

.title {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
}

.button {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (min-width: 480px) {
  .title {
    font-size: 3rem;
  }
  .button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 4rem;
  }
  .button {
    font-size: 1.5rem;
    padding: 1rem 2rem;
  }
}

@media (min-width: 1024px) {
  .title {
    font-size: 6rem;
  }
  .button {
    font-size: 1.75rem;
    padding: 1.25rem 2.5rem;
  }
}
