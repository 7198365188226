.gigItem {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #ccc;
  padding: 20px;
}

.gigDate,
.gigDetails {
  align-self: center;
}

.gigDate {
  min-width: 70px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: normal;
  white-space: nowrap;
}

.day {
  font-size: 1.2em;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.date {
  font-size: 2.5em;
  font-weight: bold;
}

.month {
  font-size: 1em;
  text-transform: uppercase;
}

.gigTitle {
  font-size: 1.5em;
  margin: 0;
}

.gigLocation {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .gigItem {
    align-items: center;
    padding: 10px;
  }

  .day, .month {
    font-size: 1em;
  }

  .date {
    font-size: 2rem;
  }

  .gigDetails {
    width: 100%;
  }

  .gigTitle, .gigLocation {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .day, .month {
    font-size: 0.9em;
  }

  .date {
    font-size: 1.5rem;
  }
}
