.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
  color: #fff;
  background: url('../../assets/gigs.png') no-repeat center center / cover fixed; /* Reintroduce parallax with 'fixed' */
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.footerContent {
  position: relative;
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.footerInfo {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 20px;
}

.socialMedia {
  display: flex;
  gap: 20px;
}

.logoText {
  font-size: 1.7rem;
  font-family: "Fugaz One", sans-serif;
  font-weight: 400;
}

.contact, .copyRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact a {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.contact a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 7px;
}

.copyRight {
  align-items: flex-end;
  text-align: right;
  font-size: 0.8rem;
}

.footerLogo {
  width: 140px;
  height: 140px;
}

svg {
  fill: #fff;
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

a:hover svg {
  transform: scale(1.1);
}

p {
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .footer {
    background-attachment: scroll;
  }

  .footerContent {
    flex-direction: column;
    gap: 20px;
  }

  .copyRight {
    align-items: center;
    text-align: center;
    border-top: 2px solid white;
    padding-top: 10px;
  }
}
