.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.menuIcon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 2;
}

.navLinks {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.navLinks li a {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.navLinks li a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 7px;
}

@media (max-width: 768px) {
  .menuIcon {
    display: block;
  }

  .navLinks {
    position: fixed;
    top: 0;
    right: 0;
    width: 200px;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    transition: transform 0.3s ease;
  }

  .navLinks.showMenu {
    transform: translateX(0);
  }

  .navLinks li {
    margin-bottom: 20px;
  }
}
