.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(225, 225, 225, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  position: relative;
  padding: 40px 20px;
  background-color: black;
  width: 90%;
  max-width: 500px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeIcon {
  position: absolute;
  top: 20px; 
  right: 20px; 
  font-size: 30px;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  margin: 0;
}

.label {
  width: 100%;

  margin: 0.75rem 0;
  border-radius: 10px;
  cursor: pointer;
}

.input::placeholder,
.textarea::placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  color: #888;
}

.input,
.textarea {
  width: 100%;
  padding: 0.95rem;
  border: none;
  border-radius: 7px;
  outline: none;
  font-size: 20px;
  line-height: 1.6;
  font-family: 'Open Sans', sans-serif;
}

.textarea {
  height: 170px;
}

.submitBtn {
  margin-top: 1rem;
}

.successNotice {
  padding: 80px 20px;
  text-align: center;
}

.phone {
  margin-top: 1rem;
}

.phone a {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.phone a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 7px;
}

@media (max-width: 600px) {
  .popup {
    width: 80%;
    padding: 20px 10px;
  }

  .input, .textarea {
    font-size: 16px;
    padding: .5rem;
  }

  .label {
    margin: .5rem 0;
  }

  .input::placeholder,.textarea::placeholder {
  font-size: 16px;
}

  .submitBtn {
    font-size: 20px;
    margin-top: .75rem;
  }
}

