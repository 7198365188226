  .galleryContainer {
    padding: 4rem 0 0;
    text-align: center;
  }

  .header {
    margin-bottom: 2rem;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 2rem;
  }
  .button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    font-weight: 700;
    cursor: pointer;
    background: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .button:hover, .button:focus, .button:active {
    background-color: #e0e0e0;
    color: black;
    border-color: black;
  }

.button svg {
  width: 18px;
  height: 18px;
}
  
  .button:hover svg, .button:focus svg, .button:active svg, .button.active svg {
    fill: black;
  }
  
  .active {
    background-color: white;
    color: black;
  }

.photos, .videos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
}

.photo, .video {
  position: relative;
  width: 100%;
  padding-top: 66.67%;
  overflow: hidden;
}

.photo img, .video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.photo img {
  object-fit: cover;
}

.video video {
  object-fit: contain;
}

@media (min-width: 480px) {
  .galleryContainer {
    padding-top: 6rem;
  }

  .buttons {
    gap: 20px;
    margin-bottom: 4rem;
  }
  
  .button {
    font-size: 1.5rem;
    padding: 0.8rem 1.5rem;
  }
  
  .button svg {
    width: 36px;
    height: 36px;
  }

  .photos, .videos {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .photos, .videos {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (min-width: 1200px) {
  .photos, .videos {
    grid-template-columns: repeat(4, 1fr);
  }
}