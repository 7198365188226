.about {
  display: grid;
  grid-template-columns: 1fr; 
  gap: 20px;
  padding: 4rem 0 0;
  max-width: 1200px;
  margin: 0 auto;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 2rem;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .about {
    grid-template-columns: 1fr 1fr;
    padding: 6rem 0 0 2rem;
  }

  .textContainer {
    padding: 0;
  }
}