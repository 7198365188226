* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: black;
  color: #D4D4D8;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  all: unset;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  transition: all .3s ease-in-out;
}

button:hover {
  color: black;
  background-color: white;
}

h1, h2, h3 {
  font-family: "Fugaz One", sans-serif;
  font-weight: 400;
  color: white;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.2rem;
}

p, ul {
  list-style: none;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.7rem;
  }

  h3 {
    font-size: 30px;
  }

  p, ul {
    font-size: 20px;
  }

  button {
    padding: 10px 20px;
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 4rem;
  }
}
