.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: fixed;
  width: 100%;
  transition: background-color 0.3s ease;
  color: white;
  z-index: 1000;
}

.headerScrolled {
  background-color: black;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
}

.logo {
  height: auto;
  width: 70px;
}

.logoText {
  font-size: 1.7rem;
  font-family: "Fugaz One", sans-serif;
  font-weight: 400;
}

@media (max-width: 768px) {
  .header {
    padding: 8px;
  }

  .logo {
    width: 50px;
  }

  .logoText {
    font-size: 1.4rem;
  }
}
